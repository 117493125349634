import {ToolBar} from "../appBar/views";
import {
  Button,
  Card,
  Chip,
  Container,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  Typography
} from "@material-ui/core";
import classes from "./publishSite.module.scss";
import {Alert, Skeleton} from "@material-ui/lab";
import React, {useEffect} from "react";
import {getRoles} from "../../lib/apiClient";
import create from 'zustand'
import dayjs from '../../lib/day'
import ServerBackend, {diagramStore} from "./ServerBackend";
import {Warning} from "@material-ui/icons";
import DiffSite, {DiffSummary, useDiffStore} from "../diff/DiffSite";
import {apiKey} from "../../lib/pages";

let buildRequestDate: Date|null = null;
let buildRequestType: string|null = null;

export interface BuildStatusResult {
  currentBuild: BuildStatus
  githubBuilds: GithubBuildSummary[]
}
export interface BuildStatus {
  DATA_HASHES: {PinkQuick: string, ArticlesPink: string, QuickOrange: string, ArticlesOrange: string}
  BUILD_REQUESTED: boolean
  BUILD_IN_PROGRESS: boolean
  BUILD_PAUSE: string
  PRODUCTION_BACKENDS: string[]
  STAGING_BACKENDS: string[]
}
export interface GithubBuildSummary {
  id: string
  name: string
  head_branch: string
  head_sha: string
  event: string
  status: string
  conclusion: string
  updated_at: string
  localTime?: string
}
interface publishStore {
  builds: [],
  roles: string[],
  temp: BuildStatus,
  latestQuickBuildStatus: GithubBuildSummary,
  latestArticleBuildStatus: GithubBuildSummary,
  buildInProgress: boolean,
  buildPaused: boolean,
  publishedAt: string,
  unpauseModal: boolean,
  mostRecentSuccessfulBuild: string
  mostRecentSuccessfulArticleBuild: string
  buildArticles: () => Promise<void>
  buildWebsite: () => Promise<void>
  getStatus: () => Promise<void>
  getRoles: () => Promise<void>
  setUnpauseModal(show: boolean): () => void
  unpauseBuilds: () => Promise<void>
  switchStagingWithProduction: () => Promise<void>
}

let getStatusTimeout: NodeJS.Timeout|null = null
function resetStatusUpdate(){
  if (getStatusTimeout)
    clearTimeout(getStatusTimeout)
  getStatusTimeout = setTimeout(() => {
    (useStore.getState() as publishStore).getStatus();
  }, 60000)
}
const useStore = create((set) => ({
  builds: [],
  roles: [],
  temp: {},
  latestQuickBuildStatus: null,
  latestArticleBuildStatus: null,
  mostRecentSuccessfulBuild: '',
  mostRecentSuccessfulArticleBuild: '',
  buildRequested: false,
  buildInProgress: true,
  buildPaused: false,
  unpauseModal: false,
  getRoles: async () => {
    const roles = await getRoles();
    set(s => {return {roles}})
  },
  buildArticles: async () => {
    resetStatusUpdate()
    set(s => {return {buildRequested:true, buildInProgress:true}})
    await fetch(`https://www.phaidon.com/workers/github-api/article-build?apikey=${apiKey}`, {mode: 'cors'})
    set(s => ({buildInProgress:true}))
  },
  buildWebsite: async () => {
    resetStatusUpdate()
    set(s => {return {buildRequested:true, buildInProgress:true}})
    await fetch(`https://www.phaidon.com/workers/github-api/quick-build?apikey=${apiKey}`, {mode: 'cors'})
    set(s => ({buildInProgress:true}))
  },
  unpauseBuilds: async () => {
    resetStatusUpdate()
    set(s => ({buildInProgress:true, unpauseModal:false}))
    await fetch(`https://www.phaidon.com/workers/pages-api/clear-pause-lock?apikey=${apiKey}`, {mode: 'cors'})
    set(s => {return {buildPause: false}})
  },
  switchStagingWithProduction: async () => {
    resetStatusUpdate()
    set(s => ({buildInProgress:true, buildPause: true}))
    await fetch(`https://www.phaidon.com/workers/pages-api/switch-staging-and-production?apikey=${apiKey}`, {mode: 'cors'})
  },
  getStatus: async () => {
    let latestQuickBuildStatus = null as GithubBuildSummary|null
    let latestArticleBuildStatus = null as GithubBuildSummary|null
    let mostRecentSuccessfulBuild = 'never'
    let mostRecentSuccessfulArticleBuild ='never'
    let status = [] as GithubBuildSummary[]
    let buildRequested = false;
    let buildInProgress = true;
    let buildPaused = false;
    let publishedAt = ''
    let temp = {}
    try {

      useDiffStore.getState().refresh().catch(i => console.log(`refresh failed.`, i))
      const statusReq = await fetch(`https://www.phaidon.com/workers/github-api/build-status?apikey=${apiKey}`, {mode: 'cors'})
      if (statusReq.ok) {
        const result = await statusReq.json() as BuildStatusResult

        const dStore = diagramStore.getState()
        temp = result.currentBuild
        dStore.setHashData(result.currentBuild.DATA_HASHES)
        dStore.connectedTo('production', result.currentBuild.PRODUCTION_BACKENDS)
        dStore.connectedTo('staging', result.currentBuild.STAGING_BACKENDS)

        status = result.githubBuilds
        buildRequested = result.currentBuild.BUILD_REQUESTED
        buildInProgress = result.currentBuild.BUILD_IN_PROGRESS
        buildPaused = !!result.currentBuild.BUILD_PAUSE
        if (buildPaused) {
          console.log('result.currentBuild.BUILD_PAUSE',result.currentBuild.BUILD_PAUSE, dayjs(result.currentBuild.BUILD_PAUSE))
          publishedAt = dayjs(result.currentBuild.BUILD_PAUSE).format('YYYY-MM-DD HH:mm')
        }

        const qs = status.filter(i => i.name.indexOf('quick-build') !== -1 && i.conclusion === 'success')[0]
        if (qs) {
          mostRecentSuccessfulBuild = dayjs(qs.updated_at).fromNow()
        }
        const as = status.filter(i => i.name.indexOf('article-build') !== -1 && i.conclusion === 'success')[0]
        if (as) {
          mostRecentSuccessfulArticleBuild = dayjs(as.updated_at).fromNow()
        }
        console.log(status)
        latestQuickBuildStatus = status.filter(i => i.name.indexOf('quick-build') !== -1)[0]
        latestArticleBuildStatus = status.filter(i => i.name.indexOf('article-build') !== -1)[0]

        latestQuickBuildStatus.localTime = dayjs(latestQuickBuildStatus.updated_at).format('YYYY-MM-DD HH:mm')
        latestArticleBuildStatus.localTime = dayjs(latestArticleBuildStatus.updated_at).format('YYYY-MM-DD HH:mm')

        if (buildRequested && !buildInProgress) {
          buildInProgress = true;
        }
      }
    }catch (e){
      console.log(e)
    }

    set(s => { return {
      temp,
      mostRecentSuccessfulBuild,
      mostRecentSuccessfulArticleBuild,
      latestQuickBuildStatus,
      latestArticleBuildStatus,
      builds: status,
      buildInProgress,
      buildPaused,
      publishedAt
    }})

    resetStatusUpdate()

    const r = await fetch('https://www.phaidon.com/workers/github-api/test?apikey=6d0c240f-95cb-4178-9bdd-57e462d6d523')
    console.log(await r.json())
  },
  setUnpauseModal(show: boolean){
    set(i => ({unpauseModal:show}))
  }
}))

export function PublishSite(){
  const state = useStore() as publishStore

  useEffect( () => {
    console.log('ArticlePublish - init / get data')
    // noinspection JSIgnoredPromiseFromCall
    state.getStatus()
    // noinspection JSIgnoredPromiseFromCall
    state.getRoles()
  }, []);

  if (state.roles.length === 0)
    return <>
      <ToolBar />
      <Container className={classes.centerMiddle}>
        <Paper className={classes.card}>
          Loading...
          <LinearProgress color="secondary" />
        </Paper>
      </Container>
    </>

  // if (!state.roles.includes('apiBuild'))
  //   return <>
  //     <ToolBar />
  //     <Alert className={classes.alert} severity={"warning"} >
  //       <Typography className={classes.title} color="textSecondary" gutterBottom>
  //         You don't appear to have access to this function.
  //         <br/> Please contact support for more information.
  //       </Typography>
  //     </Alert>
  //   </>

  function permissionButton(requiredPermission: string, comp: any){
    if(!state.roles.includes(requiredPermission)){
      return <Button disabled variant="contained">Access level not met.</Button>
    }
    return comp
  }

  function buildButtons(){
    return <>
      <div className={classes.pb}>
      <Typography className={classes.title}  gutterBottom>
        Build Staging Site
      </Typography>
      <p>Building the staging site takes the latest data, builds the site in the cloud and deploys
         to <a href="https://staging-ejr4ur.phaidon.com/">staging-ejr4ur.phaidon.com/</a></p>
      <p>The build excludes all but the latest 100 articles for speed. If older articles need to be rebuilt
        please use the below option.</p>
      <p>Building takes about 5 - 10 minutes.</p>
        {permissionButton('apiBuild', <>{state.buildInProgress ?  <Button disabled variant="contained">
            Operation in progress...
          </Button> :
          <Button onClick={state.buildWebsite} color="primary" variant="contained">
            Build Staging Site
          </Button>
        }</>)}
    </div>

    <div className={classes.pb}>
      <Typography className={classes.title}  gutterBottom>
        Build All Articles
      </Typography>
      <p>Builds the all articles and publish to <a href="https://staging-ejr4ur.phaidon.com/">staging-ejr4ur.phaidon.com/</a></p>
      <p>Building can take upto 15 minutes.</p>
      {permissionButton('apiBuild', <>{ state.buildInProgress ? <Button disabled variant="contained">
          Operation in progress...
        </Button> :
        <Button onClick={state.buildArticles} color="primary" variant="contained">
          Build All Articles
        </Button>
      }</>)}
    </div>
    </>
  }

  function publishSection() {
      return <>
        <Typography className={classes.title}  gutterBottom>
          Publish Staging to Production
        </Typography>
        <p>Once you are happy with <a href="https://staging-ejr4ur.phaidon.com">staging-ejr4ur.phaidon.com</a> you can publish staging
          to the production <a href="https://www.phaidon.com">www.phaidon.com</a>. New builds are suspended for 15min
          after a publish to allow rollback if required</p>
        <div>
          <DiffSummary />
        </div>
        {permissionButton('apiPublish', <>{ state.buildInProgress ? <Button disabled variant="contained">
            Operation in progress...
          </Button> :
          <Button onClick={state.switchStagingWithProduction} color="primary" variant="contained">
            Switch Staging with Production
          </Button>
        }</>)}
      </>
  }

  function buildPausedButton(){
      return <>
        <div className={classes.pb}>
        <Typography className={classes.title}  gutterBottom>
          Build Locked
        </Typography>
        <p>The site was recently been published at {state.publishedAt} and new builds are paused in case a rollback is required.
          Builds resume automatically 15min after publish
        </p>
        {permissionButton('apiPublish', <>
          { state.buildInProgress ? <Button disabled variant="contained">
            Operation in progress...
          </Button> :<Button  onClick={() => state.setUnpauseModal(true)} color="secondary" variant="contained"  startIcon={<Warning />}>
             Clear build lock
          </Button>}
          { state.buildInProgress ? <></> :<Button className={classes.ml} onClick={state.switchStagingWithProduction} variant="contained">
            Undo Publish
          </Button>}
        </>)}
      </div>
      <Modal
        open={state.unpauseModal}
        onClose={() => state.setUnpauseModal(false)}
        aria-labelledby="unpauseModal-modal-title"
        aria-describedby="unpauseModal-modal-description"
      >
        <Container className={classes.centerMiddle}>
          <Card className={classes.card}>
          <h2 id="unpauseModal-modal-title">Un-Lock Builds</h2>
            <p id="unpauseModal-modal-description">It should not be necessary to unlock builds manually,<br/>
              and will remove the ability to undo publishing actions.</p>
            <p>Are you sure you want to continue?</p>
          <div>
            <Button onClick={state.unpauseBuilds} color="secondary" variant="contained" startIcon={<Warning />}>
              Confirm clear build lock
            </Button>
            <Button onClick={() => state.setUnpauseModal(false)} variant="contained">Cancel</Button>
          </div>
          </Card>
        </Container>
      </Modal>
    </>
  }

  return <>
    <ToolBar />
    <Container className={classes.centerMiddle}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.title}  gutterBottom>
              Build Status
            </Typography>
            {getBuildStatus('Latest Build', state.latestQuickBuildStatus)}
            {getBuildStatus('Article Build', state.latestArticleBuildStatus)}

          </Grid>
          <Grid item xs={12} md={9} >
            <div style={{height: '180px'}}>
              <ServerBackend  />
            </div>


            <div className={classes.pb}>
              <div>Last successful quick build: {state.mostRecentSuccessfulBuild || <Skeleton className={classes.inline} variant="text" />}</div>
              <div>Last successful all article build: {state.mostRecentSuccessfulArticleBuild || <Skeleton className={classes.inline} variant="text"  />}</div>
            </div>

            {state.buildPaused ? buildPausedButton() : buildButtons() }
            {state.buildPaused ? <div/> :
              <div className={classes.pb}>
                {publishSection()}
              </div>
            }
          </Grid>
        </Grid>
      </Card>
    </Container>
    <Container className={classes.center}>
    <div>
      <h1>Recent Builds</h1>
      <table>
        <tbody>
          {state.builds.map(getBuildTag)}
        </tbody>
      </table>
    </div>
    </Container>
  </>
}

function conclusion(i: GithubBuildSummary){

  switch(i.conclusion) {
    case 'success': return <Chip size="small" color="default" label="Success" />
    case 'action_required': return <Chip size="small" color="secondary" label="Action Required"/>
    case 'cancelled': return <Chip size="small" color="secondary" label="Cancelled"/>
    case 'failure': return <Chip size="small" color="secondary" label="Failure"/>
    case 'neutral': return <Chip size="small" color="secondary" label="Neutral"/>
    case 'skipped': return <Chip size="small" color="secondary" label="Skipped"/>
    case 'stale': return <Chip size="small" color="secondary" label="Stale"/>
    case 'timed_out': return <Chip size="small" color="secondary" label="Timed Out"/>
    case 'in_progress': return <Chip size="small" color="secondary" label="In Progress"/>
    default:
      if (i.status === 'in_progress')
        return <Chip size="small" color="secondary" label={"In Progress"}/>
      return <Chip size="small" color="secondary" label={i.status}/>
  }
}
function getBuildStatus(title: string, i: GithubBuildSummary) {
  if (i == null)
    return <div>
      {title}
      <Skeleton className="space-below" variant="rect" width={'100%'} height={20} />
      <Skeleton className="space-below" variant="rect" width={'100%'} height={20} />
    </div>

  return <div className={classes.buildstatus}>
    <div className={classes.lh24}>{title} {conclusion(i)}</div>
    <div>{i.localTime}</div>
  </div>
}
function getBuildTag(i: GithubBuildSummary){
  i.localTime = dayjs(i.updated_at).format('YYYY-MM-DD HH:mm')
  return <tr key={i.id}>
    <td>{conclusion(i)}</td>
    <td>{i.localTime}</td>
    <td>{i.name.indexOf('full-article') !== -1 ? 'Article Build' : 'Quick Build'}</td>
    {/*<td>{i.event == 'push' ? 'Data Trigger' : 'Manual Trigger'}</td>*/}
  </tr>

}