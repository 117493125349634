import AppBar from '@material-ui/core/AppBar';
import {IconButton, Menu, MenuItem, Toolbar} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import React from "react";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import {useNavigate} from "react-router-dom";
import classes from "./AppBar.module.scss";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';

function hardNavigate(destination: string){
    window.location.href = destination
}

export function ToolBar(){
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return <>
        <AppBar position="static">
            <Toolbar>
                <IconButton onClick={() => navigate('/')}>
                    <HomeIcon />
                </IconButton>
                <Typography variant="h6" component="div" className={classes.title}>
                    Phaidon
                </Typography>
                <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                    <SettingsIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem onClick={() => { handleClose(); hardNavigate('/account') }}>
                <PersonIcon className={classes.padRight} />
                Account
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); hardNavigate('/account/logout') }}>
                <ExitToAppIcon className={classes.padRight}  />
                Logout
            </MenuItem>
        </Menu>
    </>

}