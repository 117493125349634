import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface FormState {
     changedState: any
}
const initialState: FormState = {
    changedState: null
}
export const formStateSlice = createSlice({
    name: 'formState',
    initialState,
    reducers: {
        setDirtyState: (state, action:PayloadAction<any>) => {
            console.log('FORM STATE SET')
            state.changedState = action.payload

        }
    }
});

export const {setDirtyState} = formStateSlice.actions
export const selectFormState = (state: RootState) => state.formState;
export default formStateSlice.reducer;
