import {get} from '../../lib/apiClient'

export interface Asset {
  filename: string
  alt: string
  md5: string
  isbn: string
  publish: boolean
}

export async function getAssets(isbn: string): Promise<Asset[]> {
  if (!isbn)
    return []
  return get<Asset[]>(`query/EditionPublishedAssetsByIsbn?isbn=${isbn}`)
}
//9781838666040