import {Container, createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import MonacoEditor from '@monaco-editor/react';
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding:{
      padding:0,
      margin:0
    },
    text: {
      margin: 0,
      width: '100%'
    },
  }),
);

export function HtmlEditorWidget(props: any) {
  const {value, onChange, rawErrors, id, label} = props;
  const classes = useStyles();

  const options = {
    lineNumbers: "off",
    wordWrap: "on",
    scrollBeyondLastLine: "off"
  };

  function editorOnChange(value:any, event:any) {
    onChange(value)
  }

  return <Container className={classes.noPadding}>
    {/*<TextField*/}
    {/*  className={classes.text}*/}
    {/*  id={id}*/}
    {/*  error={!!rawErrors}*/}
    {/*  disabled*/}
    {/*  label={label}*/}
    {/*  helperText={rawErrors}*/}
    {/*  value={value}*/}
    {/*/>*/}
    <label style={{marginTop: '20px', marginBottom: '-25px'}}
           className="MuiTypography-caption MuiTypography-colorTextSecondary">
      {label}</label>
    <MonacoEditor
      width="90vw"
      height="300px"
      language="html"
      theme="vs-light"
      value={value}
      options={options as any}
      onChange={editorOnChange}
    />
  </Container>


  // return <>
  //   {value ? <TextField
  //     className={classes.text}
  //     id={id}
  //     error={!!rawErrors}
  //     disabled
  //     label={label}
  //     helperText={rawErrors}
  //     value={value}
  //   /> : ''}
  //</>
}
