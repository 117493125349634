import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import DocumentClassList from "./features/documentClassList/DocumentClassList"
import DocumentList from "./features/documentList/DocumentList"
import {EditionSearchView} from "./features/editionPicker/views";
import {EditionAssetsView} from "./features/editionAssets/views";
import {TemplateView} from "./features/templates/views";
import {HomeView} from "./features/home/Home";
import {PublishSite} from "./features/publishSite/PublishSite";
import {JsonView} from "./features/editor/jsonView";
import {EditNewView} from "./features/editor/editNewView";
import {EditExitingView} from "./features/editor/editExitingView";
import DiffSite from "./features/diff/DiffSite";

function App() {
  return (
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/publish-site" element={<PublishSite />} />
        <Route path="/publish-site/differences" element={<DiffSite />} />
        <Route path="/template/:template/*" element={<TemplateView />} />
        <Route path="/edition-assets" element={<EditionSearchView />} />
        <Route path="/edition-assets/:sku" element={<EditionAssetsView />} />
        <Route path="/json-editor" element={<DocumentClassList />} />
        <Route path="/json-editor/:documentClass" element={<DocumentList />} />
        <Route path="/json-editor/:documentClass/:documentId/view" element={<JsonView />} />
        <Route path="/json-editor/:documentClass/new" element={<EditNewView />} />
        <Route path="/json-editor/:documentClass/:documentId" element={<EditExitingView />} />
      </Routes>
  );
}

export default App;
