import {get, post} from '../../lib/apiClient'

export interface EditorDocument {
  readonly: boolean
  keyPath?: string
  uri: string
  json: string
  jsonSchema: string
  jsonUiSchema: string
}

export interface SaveResponse {
  success: boolean
  serverRendition: string
  errors: JsonValidationError[]
  documentClass?: string,
  documentId?: string
}

export interface JsonValidationError {
  kind: string
  path: string
  property: string
  lineNumber: number
  linePosition: number
  hasLineInfo: boolean
}

export async function getDocument({documentClass, documentId}: {documentClass: string, documentId: string}): Promise<EditorDocument> {
  return get<EditorDocument>(`json-documents/${documentClass}/${documentId}`)
}

export async function getEmpty({documentClass}: {documentClass: string}): Promise<EditorDocument>{
  return get<EditorDocument>(`json-documents/schema/${documentClass}`)
}

export async function postDocument({documentClass, documentId, formData}: {documentClass: string, documentId: string, formData: any}): Promise<SaveResponse> {
  console.log('postDocument')
  return Object.assign(
    {documentClass, documentId, },
    await post<SaveResponse>(`json-documents/${documentClass}/${documentId}`,
      JSON.stringify(formData, null, 2)));
}
