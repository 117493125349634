import {
  Box,
  Button, createStyles,
  Dialog,
  Grid,
  LinearProgress, Link,
  makeStyles,
  Paper, Theme,
  Typography
} from '@material-ui/core'
import React, {useEffect, useState} from "react";
import {EditionPicker} from "../editionPicker/editionPicker";
import {selectEditionPicker, getAssetsAsync} from "./editionImagePicker.Slice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import styles from "../editionPicker/editionPicker.module.scss";
import Alert from "@material-ui/lab/Alert/Alert";
import {EditionSummary} from "../editionPicker/editionPickerApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fiftySquare: {
      width: '90px',
      height: '90px',
    },
  }),
);

export function EditionImagePickerDialog(props: any) {
  const { onClose, selectedValue, open, isbn: propsIsbn } = props;
  const e = useAppSelector(selectEditionPicker)
  const [isbn, setIsbn] = useState(propsIsbn)
  const dispatch = useAppDispatch();
  const builtStyles = useStyles()

  const handleClose = () => {
    onClose(selectedValue);
  };
  const imageSelected = (value: string) => {
    onClose(value);
  };

  useEffect(() => {
    dispatch(getAssetsAsync(isbn))
  }, [isbn, dispatch])
  useEffect(() => {
    setIsbn(propsIsbn)
  }, [propsIsbn])


  const renderLoading = () => {
    if(e.status === 'loading') {
      return <LinearProgress />
    }
    return <div className={styles.progressPlaceholder} />
  }
  const renderAlert = () => {
    if (e.status === 'error')
      return <Alert severity="error">{e.errorMessage}</Alert>
    return <></>
  }
  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {renderLoading()}
      {renderAlert()}
      <Box m={1}>
        <Box m={1}>
          <EditionPicker isbn={isbn} editionSelected={(e:EditionSummary) => {
            if (e && e.isbn) {
              setIsbn(e.isbn)
              dispatch(getAssetsAsync(e.isbn))
              console.log(`isbn set and dispatched getAssetsAsync`)
            }else{
              console.log(`EditionPicker returned without edition`)
            }
          }} />
        </Box>

        {groupBy('type', e.assets).map(group =>
          <Box m={1} key={group[0].type + group[0].md5}>
            <Typography variant="h6" >{group[0].type}</Typography>
            <Grid container justifyContent="flex-start" spacing={1}>
            {group.map((i:any) => <Grid key={i.md5} item>
                <Paper className={builtStyles.fiftySquare} onClick={() => imageSelected(i)}>
                  <img alt={i.alt} src={`/image-assets/${i.md5}?path=${i.isbn}/a/${i.assetPath}&width=90&height=90&rmode=pad&bgcolor=ffffff&clean=1`} />
                </Paper>
              </Grid>
            )}
            </Grid>
          </Box>
        )}
      </Box>
      {isbn && <Box m={1}><Link href={"/edition-assets/" + isbn } target="_blank">
        You can only select published assets. To publish more click here. (Opens a new tab)</Link>
        <Button onClick={()=> dispatch(getAssetsAsync(isbn))}>Refresh</Button>
      </Box>}
    </Dialog>
  );
}
//.filter((i:any) => i.published === 'True')

export function EditionImagePickerButton() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('notimportant');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
    console.log('selected image:', value)
  };

  return (
    <>
      <EditionImagePickerDialog selectedValue={selectedValue} open={open} onClose={handleClose} isbn="9781838662851"/>
      <br />
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
      </Button>
    </>
  );
}


function groupBy(property: string, array: any[]){
  const ret = {} as {[key: string]: any}
  for (const i of array){
    if (!(i[property] in ret)){
      ret[i[property]] = [] as any[]
    }
    ret[i[property]].push(i)
  }
  return Object.values(ret);
}
