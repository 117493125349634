import SimpleMdeReact from "react-simplemde-editor";
import React, {useEffect, useReducer, Dispatch} from "react";
import {previewRendererFactory} from "../markdown";
import {Options} from "easymde";
import {EditionImagePickerDialog} from "../../editionImagePicker/editionImagePicker";
import {Asset} from "../../editionImagePicker/editionImagePickerApi";
import {useAppSelector} from "../../../app/hooks";
import {selectFormState} from "../formState.Slice";
import {Action} from "@reduxjs/toolkit";

interface PhaidonArticleBodyMarkdownEditorState{
  value: any
  onChange: (avlue:any) => void
  documentId: string|null
  title: string|null
  titleImage: string|null
  titleDescription: string|null
  titleImageDescription: string|null
  sku: string|null
  template: string
  mdeOptions: Options
  pickerOpen: boolean
  lineAndCursor: any|null
  codemirrorInstance: any|null
}
interface ScopeAction<T> extends Action{
  name: string
  type: 'scopeAction'
  mutate(state: T): T
}
interface ImageSelectedAction extends Action{
  type:'imageSelected'
  asset:Asset
}
interface UpdateTextAction extends Action{
  type:'updateText'
  name: string
  value:string
}
interface ValueChangedAction extends Action{
  type:'valueChanged'
  value:string
}

const newArticleTemplate = `<div class="MuiPaper-root MuiAlert-root MuiAlert-standardSuccess MuiPaper-elevation0" role="alert"><div class="MuiAlert-icon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path></svg></div><div class="MuiAlert-message">You are creating a new Article - You must save the article to get full preview experience.</div></div><br><br>$content$`
const templateBase = `
<style>
article.content-section img {
    max-width: 100%;
}
.article-header {
    margin-bottom: 30px;
    background: #f0f0f0;
    padding: 30px;
    text-align: center;
}
article .article-header figure.figure {
    padding: 0;
    background: none;
    margin: 0;
}
article figure.figure {
    margin: 15px 0;
}
article figure.figure {
    margin: 35px 0;
    width: 100%;
    text-align: center;
    background-color: #f9f9f9;
    padding: 30px;
}
.figure {
    display: inline-block;
}
.article-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #000;
    border-top: 1px solid #000;
    padding-top: 25px;
}
.article-subtitle {
    border-bottom: 1px solid #000;
    padding-bottom: 25px;
}
article p {
    font-size: 16px;
    line-height: 24px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}
article a, article p a {
    color: #000;
    text-decoration: underline;
}
.sudo-nav-bar {
    height: 41px;
    background-color: #c6d7e1;
    margin-bottom: 22px;
}

</style>
<div class="sudo-nav-bar"></div>
<article class="content-section col-12 col-lg-7 col-xl-8" style="max-width: 805px; padding-left: 15px; padding-right:45px">
    <div class="article-header default-section">
        <figure class="figure">
            <img src="$titleImage$" class="figure-img img-fluid" alt="$titleImageDescription$">
            <figcaption>$titleImageDescription$</figcaption>
        </figure>
    </div> <!-- ./article-header -->

    <h1 class="article-title">$title$</h1>
    <p class="article-subtitle"><strong>$titleDescription$</strong></p>
    $content$
</article>
`
const defaultMdeOptions = {
  previewRender: previewRendererFactory(`$content$`),
  spellChecker: false,
  nativeSpellcheck: true,
  toolbar: ["bold", "italic", "heading", "unordered-list", "ordered-list", "clean-block",
    "|", "horizontal-rule", "link", {
      name: "insertimage",
      action: () => {
        console.log('insertimage invoked')
        dispatchMarkdown(mutate('opne picker', i => i.pickerOpen = true))
      },
      className: "fa fa-file-image-o",
      title: "Insert Image",
    }, // "image",
    "|", "preview", "side-by-side", "fullscreen", "|", "guide",
  ]
} as Options
let dispatchMarkdown: Dispatch<any> = () => {}

function setupTemplate(state: PhaidonArticleBodyMarkdownEditorState){
  console.warn('setupTemplate: callback', state)
  try {
    const tImage = state.titleImage!.replace(/\/image-assets\/[0-9A-Fa-f]{32}\?path=([^)]+)/g, 'https://www.phaidon.com$1');
    const customTemplate = templateBase
      .replaceAll('$titleImage$', tImage.startsWith('http') ? tImage : `https://www.phaidon.com/${tImage.replace(/^\/*/, '')}`)
      .replaceAll('$titleImageDescription$', state.titleImageDescription!)
      .replaceAll('$titleDescription$', state.titleDescription!)
      .replaceAll('$title$', state.title!)
    state.template = customTemplate
  }catch{}

  state.mdeOptions = {
    previewRender: previewRendererFactory(state.template),
    spellChecker: false,
    nativeSpellcheck: true,
    inputStyle: 'contenteditable',
    toolbar: ["bold", "italic", "heading", "unordered-list", "ordered-list", "clean-block",
      "|", "horizontal-rule", "link", {
        name: "insertimage",
        action: () => {
          console.log('insertimage invoked')
          dispatchMarkdown(mutate('opne picker', i => i.pickerOpen = true))
        },
        className: "fa fa-file-image-o",
        title: "Insert Image",
      }, // "image",
      "|", "preview", "side-by-side", "fullscreen", "|", "guide",
    ]
  }
}
function mutate(name:string, lamda: (state:PhaidonArticleBodyMarkdownEditorState) => void): ScopeAction<PhaidonArticleBodyMarkdownEditorState> {
  return {
    name,
    type: 'scopeAction',
    mutate: (state:any) => {
      const newState = Object.assign({}, state)
      lamda(newState)
      for(const key in newState)
        if(newState[key] !== state[key])
          return newState
      return state
    }
  }
}
function phaidonArticleBodyMarkdownEditorReducer(state: PhaidonArticleBodyMarkdownEditorState, action: Action) {
  // const oldState = Object.freeze(state)
  // console.log('phaidonArticleBodyMarkdownEditorReducer', action)
  //console.log('ACTION 45',state,action)
  switch (action.type) {
    case 'scopeAction':
      state = (action as ScopeAction<PhaidonArticleBodyMarkdownEditorState>).mutate(state)
      break;
    case 'imageSelected':
      console.log('imageSelected', action)
      const ns = Object.assign({}, state, )
      const asset = (action as ImageSelectedAction).asset
      if (state.value && state.codemirrorInstance && asset) {
        const startPoint = Object.assign({}, ns.codemirrorInstance.getCursor('start'))
        const endPoint = Object.assign({}, ns.codemirrorInstance.getCursor('end'))
        const text = ns.codemirrorInstance.getLine(startPoint.line);
        const start = text.slice(0, startPoint.ch);
        const end = text.slice(startPoint.ch);
        ns.codemirrorInstance.replaceRange(`${start} ![${asset.alt || state.title || ''}](/image-assets/${asset.md5}?path=/images/${asset.isbn}/a/${asset.filename}) ${end}`, {line: startPoint.line, ch: 0,});
        ns.codemirrorInstance.setSelection(startPoint, endPoint);
        ns.codemirrorInstance.focus();
      }
      state = ns
      setTimeout(() => {dispatchMarkdown(mutate('close picker', i => i.pickerOpen = false))}, 1)
      break
    case 'updateText':
      const a = action as UpdateTextAction
      const s = state as any
      //console.log(`UPDATE 45: name: ${a.name} new:${a.value} old:${s[a.name]}`)
      if (s[a.name] !== a.value){
        const n = {} as any
        n[a.name] = a.value
        state = {...state, ...n}
        setupTemplate(state)
      }
      break
    case 'valueChanged':

      const uta = action as UpdateTextAction
      state.onChange(uta.value)
      // if (uta.value !== state.value) {
      //   state = Object.assign({}, state, {value: uta.value})
      //   setTimeout(() => {state.onChange(uta.value)}, 1)
      // }
      break
  }
  // if(state !== oldState)
  //    console.log('phaidonArticleBodyMarkdownEditorReducer complete, state changed?', state !== oldState, action, state)
  return state
}

let codemirrorRefreshed = false
export function PhaidonArticleBodyMarkdownEditor(props: any) {
  const e = useAppSelector(selectFormState)
  const [state, localDispatch] = useReducer(phaidonArticleBodyMarkdownEditorReducer, {
    initialized: false,
    value: props.value,
    onChange: props.onChange,
    documentId: props?.formContext?.documentId,
    title: props?.formContext?.json?.title,
    titleImage: props?.formContext?.json?.titleImage,
    titleDescription: props?.formContext?.json?.titleDescription,
    titleImageDescription: props?.formContext?.json?.titleImageDescription,
    sku: null,
    template: newArticleTemplate,
    mdeOptions: defaultMdeOptions,
    pickerOpen: false,
    lineAndCursor: null,
    codemirrorInstance: null,
  } as PhaidonArticleBodyMarkdownEditorState)

  dispatchMarkdown = localDispatch
  useEffect(() => {
    //console.log('EFFECT 45', e)

    if (e?.changedState?.formData?.title || e?.changedState?.title)
      localDispatch({type: 'updateText', name: 'title', value: e?.changedState?.title || e?.changedState?.formData?.title} as UpdateTextAction)

    if (e?.changedState?.formData?.titleDescription || e?.changedState?.titleDescription)
      localDispatch({type: 'updateText', name: 'titleDescription', value:e?.changedState?.titleDescription || e?.changedState?.formData?.titleDescription} as UpdateTextAction)

    if (e?.changedState?.formData?.titleImage || e?.changedState?.titleImage)
      localDispatch({type: 'updateText', name: 'titleImage', value: e?.changedState?.titleImage || e?.changedState?.formData?.titleImage} as UpdateTextAction)

    if (e?.changedState?.formData?.titleImageDescription || e?.changedState?.titleImageDescription)
      localDispatch({type: 'updateText', name: 'titleImageDescription', value: e?.changedState?.titleImageDescription || e?.changedState?.formData?.titleImageDescription} as UpdateTextAction)

    if (e?.changedState?.formData?.relatedSku || e?.changedState?.relatedSku)
      localDispatch({type: 'updateText', name: 'sku', value: e?.changedState?.relatedSku || e?.changedState?.formData?.relatedSku} as UpdateTextAction)
  }, [e.changedState])

  useEffect(() => localDispatch(mutate('props changed',i=>i.value = props.value)), [props.value])

  useEffect(() => {
    if (!state.codemirrorInstance)
      return
    if(codemirrorRefreshed)
      return
    codemirrorRefreshed = true
    setupTemplate(state)
  }, [state.codemirrorInstance])

  return <><SimpleMdeReact
      value={state.value}
      onChange={(value:any) => localDispatch({type: 'valueChanged', value} as ValueChangedAction)}
      options={state.mdeOptions}
      getCodemirrorInstance={(i) => localDispatch(mutate('setCodeMirror',j => j.codemirrorInstance = i))}
      //getLineAndCursor={(i) => localDispatch(mutate('setLineAndCursor',j => j.lineAndCursor = i))}
  />
    <EditionImagePickerDialog
      open={state.pickerOpen}
      onClose={(asset:Asset) => localDispatch({type: 'imageSelected', asset} as ImageSelectedAction)}
      isbn={state.sku} />
  </>
}
