import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {getRenderedTemplate} from "./template.api";

export interface TemplateState {
  status: 'init' | 'loading' | 'ready' | 'saving' | 'error'
  errorMessage: string
  template: string | null,
  data: any,
  rendered: string
}

const initialState: TemplateState = {
  status: 'init',
  errorMessage: '',
  template: '',
  data: {},
  rendered: ''
}

export const getDataAndTemplateAsync = createAsyncThunk('templateState/getRenderedTemplate', getRenderedTemplate);

export const templateSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataAndTemplateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDataAndTemplateAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!';
      })
      .addCase(getDataAndTemplateAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.rendered = action.payload ?? ''
      })
  },
});

export const selectTemplate = (state: RootState) => state.template;
export default templateSlice.reducer;
