import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Container, LinearProgress} from "@material-ui/core";
import {getDataAndTemplateAsync, selectTemplate} from "./template.slice";
import {Alert} from "@material-ui/lab";
import React from "react";
import { Fragment } from "react";

export function TemplateView(){
    const params = useParams()
    const dispatch = useAppDispatch()
    const state = useAppSelector(selectTemplate)
    useEffect(()=>{
        dispatch(getDataAndTemplateAsync({templateUri: params.template, dataUri: params['*']}))
    },[dispatch, params])

    function renderStatus() {
        if(state.status === 'error')
            return <Alert severity="error">{state.errorMessage}</Alert>

        return <Container maxWidth="xs">
            <LinearProgress />
            {state.status}
        </Container>
    }

    return <>
        {state.status === 'ready' ? <div dangerouslySetInnerHTML={{__html: state.rendered}}/> : renderStatus()}
    </>
}
