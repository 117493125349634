import { useAppDispatch, useAppSelector} from "../../app/hooks";
import {Grid, LinearProgress} from "@material-ui/core";
import React, { useEffect, useReducer} from "react";
import {refreshSummaryAsync, selectEditionPicker} from "./editionPicker.Slice";

import styles from "./editionPicker.module.scss"
import {EditionSummary} from "./editionPickerApi";
import {EditionAutocomplete} from "./editionPickert.autocomplete";
import {Action} from "@reduxjs/toolkit";


interface EditionPickerLocalAction extends Action{
  isbn?: string|null
  editions?: EditionSummary[]
}
interface EditionPickerLocalState{
  isbn: string|null,
  edition: EditionSummary|null
  editions: EditionSummary[]

  editionChanged: ((edition: EditionSummary | null) => void)|null;
}
function firstOrNull<T>(arr: T[]): T|null{
  if(arr && arr.length > 0)
    return arr[0]
  return null
}
function editionPickerReducer(state: EditionPickerLocalState, action: EditionPickerLocalAction): EditionPickerLocalState{
  console.log('editionPickerReducer', action)
  const oldState = state
  switch(action.type){
    case 'setIsbn':
      if (state.isbn !== action.isbn!)
        state = Object.assign({}, state,{isbn: action.isbn}) as any
      break
    case 'setEditions':
      if (state.editions !== action.editions!)
        state = Object.assign({}, state,{editions: action.editions || []}) as any
      break
  }
  if(oldState === state)
    return state

  console.log('editionPickerReducer - state has changed')
  const oldEdition = state.edition
  let e = state.edition
  if(state.isbn && state.editions && state.editions.length > 0)
    e = firstOrNull(state.editions.filter(i => i.isbn === state.isbn))
  else
    e = null
  state = Object.assign({}, state, {edition: e})

  if(state.editionChanged && e !== oldEdition) {
    setTimeout(() => {
      if (state.editionChanged)
        state.editionChanged(e)
    }, 1)
  }

  return state
}

export function EditionPicker(props: any) {
  const e = useAppSelector(selectEditionPicker)
  const dispatch = useAppDispatch();
  const label = props.label || 'Search editions...'


  const [state, localDispatch] = useReducer(editionPickerReducer, {
    isbn: props.isbn,
    edition: null,
    editions: [],
    editionChanged: props.editionSelected
  } as EditionPickerLocalState)
  console.log('EditionPicker', props, state)

  useEffect(()=> {
    console.log('---> setEditions', e.editions)

    localDispatch({type: 'setEditions', editions: e.editions})
  }, [e.editions])

  useEffect(()=> {
    console.log('---> setIsbn',props.isbn)
    localDispatch({type: 'setIsbn', isbn: props.isbn})
  }, [props.isbn])

  useEffect(() => {
    console.log('---> refreshSummaryAsync')
    dispatch(refreshSummaryAsync());
  }, [dispatch])

  return (
      <Grid container>
        { e.status === 'loading' ? <LinearProgress /> : <div className={styles.progressPlaceholder} />}
        <EditionAutocomplete
            label={label}
            current={state.edition}
            summary={e.editions}
            selected={(edition) => localDispatch({type: 'setIsbn', isbn: edition ? edition.isbn : null})}
        />
      </Grid>
  )
}