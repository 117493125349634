import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getList } from './documentApi';

export interface DocumentListState {
  status: 'init' | 'loading' | 'ready'
  filter: string
  documentClass: string
  listing: any[]
  renderedListing: any[]
}

const initialState: DocumentListState = {
  status: 'init',
  filter: '',
  documentClass: 'init',
  listing: [],
  renderedListing: []
}
export const refreshAsync = createAsyncThunk('documentList/refresh', getList);
export const documentListSlice = createSlice({
  name: 'documentList',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(refreshAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.listing = action.payload
        state.renderedListing = action.payload
        state.filter = ''
      });
  },
});

export const selectDocumentListSlice = (state: RootState) => state.documentList;
export default documentListSlice.reducer;
