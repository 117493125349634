import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import editorReducer from '../features/editor/editorSlice'
import documentClassListReducer from '../features/documentClassList/documentClassListSlice'
import documentListReducer from '../features/documentList/documentListSlice'
import editionPickerReducer from "../features/editionPicker/editionPicker.Slice";
import editionImagePickerReducer from "../features/editionImagePicker/editionImagePicker.Slice";
import editionAssetsReducer from "../features/editionAssets/editionAssets.Slice";
import templateReducer from "../features/templates/template.slice";
import formState from "../features/editor/formState.Slice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    editor: editorReducer,
    documentClassList: documentClassListReducer,
    documentList: documentListReducer,
    editionPicker: editionPickerReducer,
    editionImagePicker: editionImagePickerReducer,
    editionAssets: editionAssetsReducer,
    template: templateReducer,
    formState: formState
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
