import {Component} from "react";
import {DocumentListState, refreshAsync, selectDocumentListSlice} from "./documentListSlice";
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect, ConnectedProps} from "react-redux";
import {Container, Paper, Box, Checkbox} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import {ToolBar} from "../appBar/views";

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux, DocumentListState {
  params: any
  navigate: (uri: string) => void
}

export class DocumentList extends Component<Props, DocumentListState> {
  refresh() {
    console.log('DocumentList: refresh', this)
    this.props.dispatch(refreshAsync(this.props.params.documentClass) as any)
  }
  UNSAFE_componentWillMount() {
    this.refresh();
  }
  componentDidMount() {
      document.title = `${this.props.params.documentClass} List - Phaidon Editor`
  }
  handleInput = (value: string) => {
    this.setState({renderedListing: filterOnAnyProp(this.props.listing, value)})
  }
  handleInputThrottled = debounce(this.handleInput, 300)
  render() {
    return <>
      <ToolBar />
      <Container>
        <Box m={2}>
          <Container>
            <TextField id="standard-basic"
                       style={{width: 'calc(100% - 65px)'}}
                       label="Filter"
                       onChange={e => {this.setState({filter: e.target.value}); this.handleInputThrottled(e.target.value)}} />
            <Button onClick={() => this.props.navigate(`/json-editor/${this.props.params.documentClass}/new`)} variant="contained">New</Button>
          </Container>
          <Paper className="container" elevation={3} >
              {this.props.status === 'ready' ? this.renderList() : this.renderLoading() }

          </Paper>
        </Box>
      </Container>
    </>
  }
  renderLoading() {
    return <Box m={1}><CircularProgress /></Box>
  }
  renderValue(v:any){
    if(typeof v === 'boolean') {
      if(v)
       return <Checkbox checked disabled />
      return <Checkbox disabled />
    }
    return <>{v}</>
  }
  renderList = () => {
    const listing = this.state ? this.state.renderedListing : this.props.renderedListing || []

    if (!listing || listing.length === 0 )
      return <div><Box m={4}>No Results Found. <Button onClick={() => this.refresh()}>Refresh</Button></Box></div>

    const keys = Object
      .keys(listing[0])
      .filter(i => i !== 'documentId' && i !== 'documentClass')
    const max = 200;

    return <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell component="th"/>
              { keys.map(i => <TableCell key={i} component="th">{i}</TableCell>) }
            </TableRow>
          </TableHead>
          <TableBody>
            { listing.slice(0, max).map(i => <TableRow  key={i.documentId}>
              <TableCell>
                <Button onClick={ () => this.props.navigate(`/json-editor/${i.documentClass}/${i.documentId}`)}>Edit</Button>
                <Button onClick={ () => this.props.navigate(`/json-editor/${i.documentClass}/${i.documentId}/view`)}>View</Button>
              </TableCell>
              {keys.map((k,idx) => <TableCell key={`${idx}-${i[k]}`}>{this.renderValue(i[k])}</TableCell>)}
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <span>Showing: {Math.min(listing.length, max)} of {listing.length}</span>
        <Button onClick={() => this.refresh()}>Refresh</Button>
      </Box>
    </>
  }
}

const withRouter = (WrappedComponent: any) => {
  return (props: any) => {
    const params = useParams();
    return (
      <WrappedComponent
        {...props}
        params={params}
        navigate={useNavigate()}
        // etc...
      />
    );
  }
};
function filterOnAnyProp(list: any[], filter: string){
  if (!filter)
    return list
  filter = filter.toLowerCase()
  const keys = Object.keys(list[0])
  const ret = []as any[]
  for (const row of list) {
    for (const k of keys) {
      if (typeof(row[k]) === 'string' && row[k].toLowerCase().indexOf(filter) !== -1) {
        ret.push(row)
        break
      }
    }
  }
  return ret
}
function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: any
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
const connector = connect(selectDocumentListSlice)
export default withRouter(connector(DocumentList))
