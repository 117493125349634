import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {refreshAsync, saveAsync, selectEditor} from "./editorSlice";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ToolBar} from "../appBar/views";
import {Editor} from "./Editor";

export function EditExitingView() {
  const e = useAppSelector(selectEditor)
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(e.status)
  const [errorMessage, setErrorMessage] = useState(e.errorMessage)
  const params = useParams();
  const {documentClass, documentId} = params;

  useEffect(() => {
    if (documentClass && documentId) {
      dispatch(refreshAsync({documentClass, documentId}))
    }
  }, [dispatch, documentClass, documentId])

  const save = async (data: any, event: any) => {
    if (documentClass && documentId) {
      console.log('SAVE', data.formData)
      await dispatch(saveAsync({documentClass, documentId, formData: data.formData}));
    }
  };
  useEffect(() => {
    setStatus(e.status)
    setErrorMessage(e.errorMessage)
    console.log(e.status, e.errorMessage)
  }, [e.status, e.errorMessage])

  return <>
    <ToolBar/>
    <Editor
      documentClass={documentClass}
      documentId={documentId}
      json={e.json}
      jsonSchema={e.jsonSchema}
      jsonUiSchema={e.jsonUiSchema}
      formContext={e}
      save={save}
      status={status}
      errorMessage={errorMessage}
    />
  </>
}