import {
  Chip,
  Grid,
  TextField
} from "@material-ui/core";
import {Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason} from "@material-ui/lab";
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import styles from "./editionPicker.module.scss"
import {EditionSummary} from "./editionPickerApi";
import React, {ChangeEvent, useEffect, useState} from "react";

export function EditionAutocomplete(props: {label: string, current: EditionSummary | null, summary: EditionSummary[], selected: (summary: EditionSummary | null) => void}){
  const {label, current, summary, selected} = props;
  const [currentEdition, setCurrentEdition] = useState(current)
  const [summaryState, setSummaryState] = useState(summary)
  console.log(`EditionAutocomplete: ${current?.title ?? '- none -'}`, summaryState.length)
  function renderAutocompleteImg(option: EditionSummary){
    if (option.md5)
      return <img alt="" className={styles.autocomplete} src={`/image-assets/${option.md5}?width=32&height=32&rmode=pad&bgcolor=ffffff&clean=1`} />
    return <div className={styles.noimg} />
  }
  const change = (e:ChangeEvent<{}>, d:EditionSummary|null, reason:AutocompleteChangeReason, details?:AutocompleteChangeDetails<EditionSummary|null>) => {
    //setCurrentEdition(d)
    console.log('EditionAutocomplete change', d)
    selected(d)
  }
  useEffect(()=> {
    selected(currentEdition)
  }, [currentEdition])

  useEffect(()=>{
    setSummaryState(summary)
  },[summary])

  useEffect(()=>{
    setCurrentEdition(current)
  },[current])

  return <>
    <Autocomplete
        onChange={change}
        options={summaryState}
        value={currentEdition}
        getOptionLabel={(option) => `${option.isbn}: ${option.title} [${option.language}]` }
        getOptionSelected={(option:any, value:any) => option.isbn === value.isbn}
        style={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label={label} placeholder="ISBN or title"  />}
        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        clearOnBlur
        blurOnSelect
        clearOnEscape
        renderOption={(option) => <Grid alignItems="center" container >
          {renderAutocompleteImg(option)}
          {option.isbn}: {option.title}
          <Chip className={styles.ml5} label={option.language}/>
        </Grid>}
    />
  </>
}



const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child: React.ReactNode) =>  itemSize
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref} >
      <OuterElementContext.Provider value={other} >
        <VariableSizeList
          style={{overflowX: 'hidden'}}
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

