import React, {useCallback, useContext, useEffect, useState} from 'react';
import { useAppDispatch } from '../../app/hooks';
import Form from "@rjsf/material-ui";
import styles from './Editor.module.scss'
import {Box, LinearProgress} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert/Alert';
import "easymde/dist/easymde.min.css";
import {PhaidonArticleBodyMarkdownEditor} from "./widgets/phaidonArticleBodyMarkdownEditor";
import {GuidWidget} from "./widgets/guid";
import {EditionPickerWidget} from "./widgets/EditionPickerWidget";
import {EditionImagePickerWidget} from "./widgets/editionImagePickerWidget";
import {HiddenWhenBlankWidget} from "./widgets/hiddenWhenBlank";
import {setDirtyState} from "./formState.Slice";
import deepEqual from "deep-equal"
import {UNSAFE_NavigationContext as NavigationContext} from "react-router";
import {HtmlEditorWidget} from "./widgets/htmlEditor";

const widgets = {
  PhaidonArticleBodyMarkdownEditor,
  HtmlEditorWidget,
  GuidWidget,
  EditionPickerWidget,
  EditionImagePickerWidget,
  HiddenWhenBlankWidget
};
let dirty = false
let _oldState = undefined as any
function setCleanState(s: any){
  console.log('CLEAN STATE SET:', s)
  _oldState = s
  dirty=false
}
function checkIfDirty(newState: any){
  if(dirty)return true
  const changed = !deepEqual(newState, _oldState)
  console.log(`checkIfDirty[${changed}]`,newState, _oldState)
  return changed
}
const defaultChangeState = {
    title: null,
    titleDescription: null,
    titleImage: null,
    titleImageDescription: null
}
let changeState = defaultChangeState;

let unblock = null as any;
let saving = false;
export function Editor(props: any) {
  const dispatch = useAppDispatch()
  const [ json, setJson ] = useState(props.json)
  const [ jsonSchema, setJsonSchema ] = useState(props.jsonSchema)
  const [ jsonUiSchema, setJsonUiSchema ] = useState(props.jsonUiSchema)
  const { navigator } = useContext( NavigationContext ) as any;

  const blocker = useCallback(( tx:any ) => {
      console.log('blocker', tx, json);
      if (!checkIfDirty(json) || saving) {
        console.log('blocker - not dirty , unblock and retry');
        unblock()
        tx.retry();
        return
      }

      if (window.confirm('There are unsaved changes! Are you sure you want to leave?')){
        console.log('blocker - exit confirmed , unblock and retry');
        unblock()
        tx.retry();
        return
      }
      console.log('KEEP BLOCKING')
    },
    [json]
  );
  useEffect(() => console.log('blocker changed'), [blocker])
  useEffect(() => {
    if(unblock)
      unblock()
    unblock = navigator.block(blocker)
  }, [blocker]);

  const renderAlert = () => {
    if (props.status === 'error')
      return <Alert severity="error">{props.errorMessage}</Alert>
    return <></>
  }
  const renderLoading = () => {
    if (props.status === 'loading')
      return <LinearProgress />
    if (props.status === 'saving')
      return <LinearProgress color="secondary" />
    return <div className={styles.progressPlaceHolder} />
  }
  useEffect(()=> {
    if(props.json !== json)
      setJson(props.json)
    if(props.jsonSchema !== jsonSchema)
      setJsonSchema(props.jsonSchema)
    if(props.jsonUiSchema !== jsonUiSchema)
      setJsonUiSchema(props.jsonUiSchema)

  },[json, jsonSchema, jsonUiSchema, props])
  useEffect(() => {
    console.log('NEW DOC LOADED', {formData: json})
    setTimeout(() => dispatch(setDirtyState({formData: json})), 0)
    setCleanState(json)
    changeState = {
      title: json?.title,
      titleDescription: json?.titleDescription,
      titleImage: json?.titleImage,
      titleImageDescription: json?.titleImageDescription
    }
  }, [json])

  const change = (evt: any) => {
    if (!saving) {
      dirty = true
      const nstate = {
        title: evt.formData.title,
        titleDescription: evt.formData.titleDescription,
        titleImage: evt.formData.titleImage,
        titleImageDescription: evt.formData.titleImageDescription
      }
      if(!deepEqual(nstate, changeState)) {
        changeState = nstate
        console.log('FORM CHANGE', evt.formData)
        setTimeout(() => dispatch(setDirtyState(nstate)), 0)
      }
    }
  }
  const save = async (data:any, event:any) => {
    saving = true;
    if(unblock)
      unblock()
    await props.save(data)
    setCleanState(data)
    saving = false
  }

  return <>
    { renderLoading() }
    <div className={styles.editor}>
      { renderAlert() }
      <Box m={2}>
        <Form
          schema={jsonSchema|| {}}
          uiSchema={jsonUiSchema|| {}}
          formContext={props|| {}}
          formData={json|| {}}
          widgets={widgets}
          onSubmit={save}
          onChange={change}
        />
      </Box>
    </div>
  </>

}




