import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getEmptyAsync, saveAsync, selectEditor} from "./editorSlice";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ToolBar} from "../appBar/views";
import {Alert} from "@material-ui/lab";
import {Editor} from "./Editor";

export function EditNewView() {
  const e = useAppSelector(selectEditor)
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(e.status)
  const [errorMessage, setErrorMessage] = useState(e.errorMessage)
  const params = useParams();
  const {documentClass} = params;

  const save = async (data: any, event: any) => {
    console.log('SAVE', data)
    debugger
    if (!data || !data.formData) {
      setStatus('error')
      setErrorMessage('Save failed. No data to save.')
      return
    }

    let d = data.formData;
    for (const p of (e.keyPath ?? '').split('.')) {
      if (p in d)
        d = d[p]
      else {
        setStatus('error')
        setErrorMessage('Save failed. Cant find key from keyPath')
        return
      }
    }
    const cls = documentClass ?? ''
    await dispatch(saveAsync({documentClass: cls, documentId: d, formData: data.formData}));
  }

  useEffect(() => {
    if (e.status === 'ready' && !e.keyPath) {
      setStatus('error')
      setErrorMessage('No keyPath configured.')
    }
  }, [e.keyPath, e.status])

  useEffect(() => {
    if (documentClass) {
      console.log('getEmptyAsync')
      dispatch(getEmptyAsync({documentClass}));
    }
  }, [dispatch, documentClass])

  useEffect(() => {
    document.title = `${documentClass} - Phaidon Editor`
  }, [documentClass]);

  useEffect(() => {
    setStatus(e.status)
    setErrorMessage(e.errorMessage)
    console.log(e.status, e.errorMessage)
  }, [e.status, e.errorMessage])


  return <>
    <ToolBar/>
    <Alert>You are creating a new {documentClass}</Alert>
    <Editor
      documentClass={documentClass}
      json={e.json}
      jsonSchema={e.jsonSchema}
      jsonUiSchema={e.jsonUiSchema}
      formContext={e}
      save={save}
      status={status}
      errorMessage={errorMessage}
    />
  </>
}