import {Button, Container, createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {EditionImagePickerDialog} from "../../editionImagePicker/editionImagePicker";
import {Asset} from "../../editionImagePicker/editionImagePickerApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding:{
      padding:0,
      margin:0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'bottom',
    },
    text: {
      margin: 0,
      width: '100%',
      felxGrow: 1,
    },
  }),
);

export function EditionImagePickerWidget(props: any) {
  //const {value: guid, onChange: setGuid, rawErrors, id, placeholder, label, required, disabled, readonly, autofocus} = props;
  const {value, onChange, rawErrors, id, label} = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = (value: Asset|string) => {
    setOpen(false);
    if (typeof value === 'object') {
      onChange(`/image-assets/${value.md5}?path=/images/${value.isbn}/a/${value.filename}`)
      console.log('selected image:', value)
    }
  }

  return <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>

    <TextField
      style={{flexGrow: 1, width: '100%'}}
      id={id}
      error={!!rawErrors}
      disabled
      label={label}
      helperText={rawErrors}
      value={value}
      onClick={handleClickOpen}
    />
    <Button variant="text" size="small" color="primary" onClick={handleClickOpen}>Change</Button>

    <EditionImagePickerDialog selectedValue={value} open={open} onClose={handleClose} isbn={props?.formContext?.json?.relatedSku ?? ''}/>
  </div>


}



