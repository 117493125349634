import {get} from '../../lib/apiClient'

export interface EditionSummary {
  edition_Id: string
  isbn: string
  title: string
  md5: string
  language: string
}

export async function getEditionSummary(): Promise<EditionSummary[]> {
  return get<EditionSummary[]>(`query/EditionPicker`)
}

