import React, {Component} from "react";
import {DocumentListState, refreshAsync, selectDocumentListSlice} from "./documentClassListSlice";
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect, ConnectedProps} from "react-redux";
import {Container, Paper, List, ListItem, ListItemText, Typography, Box} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {ToolBar} from "../appBar/views";

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux, DocumentListState {
  navigate: (uri: string) => void
}


export class DocumentClassList extends Component<Props, DocumentListState> {

  refresh() {
    console.log('DocumentClassList: refresh', this)
    this.props.dispatch(refreshAsync() as any)
  }
  componentWillMount() {
    this.refresh();
  }
  componentDidMount() {
    // After the component did mount
      document.title = `Phaidon Editor`
  }
  static getDerivedStateFromError(error: any) {
    console.log('DocumentClassList:getDerivedStateFromError', error)
    return { classes: [], state: 'init' };
  }

  render() {
    return <>
      <ToolBar />
      <Container maxWidth="sm" >
        <Box m={5}>
          <Paper className="container" elevation={2}>
            <Box p={1}>
              <Typography variant="h6" component="div">
                Document Classes:
              </Typography>
              {this.props.status === 'ready' ? this.renderList() : this.renderLoading() }
              <Button onClick={() => this.refresh()}>Refresh</Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  }

  renderLoading(){
    return <CircularProgress />
  }
  renderList(){
    if (!this.props.classes || this.props.classes.length === 0)
      return this.renderLoading()
    return <List>
      {this.props.classes.map(i =>
        <ListItem key={i} button onClick={() => this.props.navigate(`/json-editor/${i}`)}>
            <ListItemText primary={i}/>
        </ListItem>
      )}
      </List>
  }
}

const withNavigate = (WrappedComponent: any) => {
  return (props: any) => {
    return (
      <WrappedComponent
        {...props}
        navigate={useNavigate()}
        // etc...
      />
    );
  }
};


const connector = connect(selectDocumentListSlice)
export default withNavigate(connector(DocumentClassList))
