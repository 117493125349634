import React, {useEffect, useRef} from "react";
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
import HtmlDiff from "htmldiff-js";
import create from "zustand";
import {apiKey} from "../../lib/pages";
import classes from "./diff-styles.module.scss";
import {ToolBar} from "../appBar/views";
import {
  Badge,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import {useNavigate} from "react-router-dom";

declare module 'htmldiff-js'
const injectStyle = `
<style>
ins {
  text-decoration: none;
  background-color: #d4fcbc;
}
ins img {
    border: 1px solid green!important;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}
del img {
    border: 1px solid red!important;
}
</style>
`;
const previewWaitingHtml = `
<html><body>
    <div style="font-family: sans-serif; text-align: center; padding: 50px;color:gray">Select a page from above to preview</div>
<body></html>
`
interface siteDiffStore {
  loaded: boolean
  selected: string
  productionManifest: {[key:string]: string}
  stagingManifest: {[key:string]: string}
  newPages: string[]
  updatedPages: string[]
  removedPages: string[]
  stagingPage: string|null
  productionPage: string|null
  listMode:string,
  previewMode: string,
  diff: string|null,
  refresh(): Promise<void>
  selectKey(key:string): Promise<void>
  updateIframe(): void,
  setListMode(m:string):void,
  setPreviewMode(m:string):void,
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const useDiffStore = create<siteDiffStore>((set, get) => ({
  loaded:false,
  selected: '',
  productionManifest: {},
  stagingManifest: {},
  newPages: [],
  updatedPages: [],
  removedPages: [],
  stagingPage: null,
  productionPage: null,
  listMode: 'created',
  previewMode: 'created',
  diff: null,
  async selectKey(key: string) {
    const request = await fetch(`https://www.phaidon.com/workers/pages-api/page?apikey=${apiKey}&page=${key}`)
    const {productionPage, stagingPage} = await request.json()
    let diff, previewMode:string|null = null;
    if(productionPage && !stagingPage) {
      diff = null
      previewMode = 'removed'
    }else if (!productionPage && stagingPage) {
      diff = null
      previewMode = 'created'
    }else{
      previewMode = 'changed'

      const iframe = document.querySelector('#preview')! as HTMLIFrameElement
      if (iframe) {
        iframe.src = "about:blank";
      }
    }
    set(({selected:key, productionPage, stagingPage, diff, previewMode}))
    get().updateIframe()
  },
  setListMode(m:string):void{
    set(({listMode:m, previewMode:m, selected:''}))
    get().updateIframe()
  },
  setPreviewMode(m:string):void{
    set(({previewMode:m}))
    get().updateIframe()
  },
  updateIframe(){
    const s = get()
    const iframe = document.querySelector('#preview')! as HTMLIFrameElement
    if (!iframe)
      return;
    iframe.src = "about:blank";

    try {
      if (!iframe)
        return;
      if (s.selected === '') {
        iframe.contentWindow!.document.open();
        iframe.contentWindow!.document.write(previewWaitingHtml);
        iframe.contentWindow!.document.close();
        return;
      }
      switch (s.previewMode) {
        case 'created':
          iframe.contentWindow!.document.open();
          iframe.contentWindow!.document.write((s.stagingPage || '')
            .replace('<head>', '<head><base href="https://staging-ejr4ur.phaidon.com/">'));
          iframe.contentWindow!.document.close();
          return;
        case 'removed':
          iframe.contentWindow!.document.open();
          iframe.contentWindow!.document.write((s.productionPage || '')
            .replace('<head>', '<head><base href="https://www.phaidon.com/">'));
          iframe.contentWindow!.document.close();
          return;
      }
      let diff = 'Differences preview failed to build. A page may be broken.'
      try {
        diff = HtmlDiff.execute(s.productionPage, s.stagingPage) as string
        console.log(diff)
      } catch (ex) {
        diff += 'Error: ' + ex
        console.log(s.productionPage?.length, s.stagingPage?.length)
      }

      if (!diff)
        diff = 'Differences preview failed to build.'

      iframe.contentWindow!.document.open();
      iframe.contentWindow!.document.write(diff
        .replace('<head>', '<head><base href="https://staging-ejr4ur.phaidon.com/">')
        .replace('</head>', injectStyle + '</head>')
      );
      iframe.contentWindow!.document.close();
    }catch(ex){
      if(ex.message.startsWith('Blocked a frame with origin')){
        iframe.src = "about:blank";
        setTimeout(() => {get().updateIframe()}, 100)
        return
      }
      throw new Error(ex)
    }
  },
  async refresh() {
    const request = await fetch(`https://www.phaidon.com/workers/pages-api/manifests?apikey=${apiKey}`)
    const {productionManifest, stagingManifest} = await request.json()
    const newPages = new Set<string>(Object.keys(stagingManifest).filter(i => i.endsWith('/index.html')))
    const removedPages = [] as string[]
    const updatedPages = [] as string[]
    for (const ppk in productionManifest) {
      if(!ppk.endsWith('/index.html'))
        continue;
      if (ppk in stagingManifest) {
        newPages.delete(ppk)
        if (productionManifest[ppk] !== stagingManifest[ppk]){
          updatedPages.push(ppk)
        }
      } else{
        removedPages.push(ppk)
      }
    }

    (window as any).productionManifest = productionManifest;
    (window as any).stagingManifest = stagingManifest;

    set(({
      loaded: true,
      productionManifest,
      stagingManifest,
      removedPages,
      updatedPages,
      newPages: Array.from(newPages),
    }))
    get().updateIframe()
  }
}));

export function DiffSummary(){
  const state = useDiffStore() as siteDiffStore

  useEffect( () => {
    if (!state.loaded) {
      console.log('ArticlePublish - init / get data')
      state.refresh()
    }
  }, []);

  return <div className={classes.pb}>
    New Pages {state.newPages.length},
    Changed Pages {state.updatedPages.length},
    Removed Pages {state.removedPages.length}
    <a className={classes.pl} href="/publish-site/differences">details</a>
  </div>
}

export default function DiffSite(){
  const [tab, setTab] = React.useState(0);
  const state = useDiffStore() as siteDiffStore
  const navigate = useNavigate();


  useEffect( () => {
    if (!state.loaded) {
      console.log('ArticlePublish - init / get data')
      state.refresh()
    }
  }, []);

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    let list = [] as string[];
    switch(state.listMode){
      case 'created': list = state.newPages; break
      case 'changed': list = state.updatedPages; break
      case 'removed': list = state.removedPages; break
    }
    return (
      <ListItem button style={style} key={list[index]} selected={list[index] === state.selected }
                onClick={() =>state.selectKey(list[index])}>
        <ListItemText primary={list[index]} />
      </ListItem>
    );
  }

  function renderDiffList(diffs: string[]) {
    if (diffs.length === 0)
      return <div>
        <p style={{color: 'gray'}}>There are no {state.listMode} pages.</p>
      </div>
    return <div>
      <FixedSizeList height={300} width={'100%'} itemSize={46} itemCount={diffs.length}>
        {renderRow}
      </FixedSizeList>
    </div>
  }
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  if (!state.loaded)
  return <>
    <ToolBar />
    <div >
      <CircularProgress color="secondary" />
    </div>
  </>

  function handleListModeSelect(event: React.ChangeEvent<{}>, tabIndex: string){
    state.setListMode(tabIndex)
  }

  function handlePreviewModeSelect(event: React.ChangeEvent<{}>, tabIndex: string){
    state.setPreviewMode(tabIndex)
  }
  return <>
    <ToolBar />
    <Tabs value={state.listMode} onChange={handleListModeSelect} >
      <Tab value="back" label="Back to publish" {...a11yProps('back-list')} onClick={() => navigate("/publish-site")} />
      <Tab value="created" label="New Pages" {...a11yProps('created-list')} />
      <Tab value="changed" label="Updated Pages" {...a11yProps('changed-list')} />
      <Tab value="removed" label="Removed Pages" {...a11yProps('removed-list')} />
    </Tabs>

      { state.listMode === 'created' ? renderDiffList(state.newPages) : null}
      { state.listMode === 'changed' ? renderDiffList(state.updatedPages) : null}
      { state.listMode === 'removed' ? renderDiffList(state.removedPages) : null}

      <Tabs value={state.previewMode} onChange={handlePreviewModeSelect} >
        <Tab disabled label="Preview:" value="-" />
        <Tab disabled={state.listMode !== 'changed' && state.listMode !== 'created'} value="created" label="View new page" {...a11yProps('created-preview')} />
        <Tab disabled={state.listMode !== 'changed'} value="changed" label="View differences" {...a11yProps('changed-preview')} />
        <Tab disabled={state.listMode !== 'changed' && state.listMode !== 'removed'} value="removed" label="View old page" {...a11yProps('removed-preview')} />
      </Tabs>
    <iframe id="preview" ></iframe>

  </>
}