import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect} from "react";
import { useParams} from "react-router-dom";
import {Box, Button, Chip, Grid, Paper} from "@material-ui/core";
import {
    EditionAssetState,
    getAssetsAsync,
    getEditionSummaryAsync,
    publishAssetAsync,
    selectEditionAssets
} from "./editionAssets.Slice";
import {Alert} from "@material-ui/lab";
import {EditionAsset} from "./editionAssetsApi";
import {ToolBar} from "../appBar/views";
import {CloudDone, CloudUpload} from "@material-ui/icons";

export function EditionAssetsView(){
    const params = useParams();
    const {sku} = params;
    const e = useAppSelector(selectEditionAssets)
    const dispatch = useAppDispatch();

    async function publish(a: EditionAsset) {
        await dispatch(publishAssetAsync(a))
        dispatch(getAssetsAsync(sku))
    }
    useEffect(() => {
        document.title = `${e.title} - Edition Assets - Phaidon Editor`
    }, [e.title]);

    useEffect(() => {
         dispatch(getAssetsAsync(sku))
         dispatch(getEditionSummaryAsync(sku))
    },[sku, dispatch])


    return <>
        <ToolBar />
        <Box p={1}>
                {e.status === 'error' && e.errorMessage && <Alert style={{position: 'fixed', zIndex: 500, top: '5px'}} severity="error">{e.errorMessage}</Alert>}
            <Box p={1}>
                <Box style={{padding: '5px'}}>
                    <Box display="grid" gridGap="15px" gridTemplateColumns={'180px 1fr'}>
                        {e.imageUri ? <img src={e.imageUri} alt="" /> : ''}
                        <div>
                            <h3>{e.title}</h3>
                            <div>{sku}</div>
                        </div>
                    </Box>
                </Box>
            </Box>
            {groupBy('assetTypeDisplayName', e.assets).map(group => <>
                    <br />
                    <Box m={1}>
                        {group[0].assetTypeDisplayName}
                    </Box>
                    {group.map((i: any) => renderAsset(i, e, publish))}
                </>)
            }
        </Box>
    </>
}



function renderAsset(a: EditionAsset, e: EditionAssetState, publish: (a: EditionAsset) => void) {

    const uri = a.published === 'True' ? `https://www.phaidon.com/images/${a.isbn}/a/small/${a.assetPath}`
        : `/image-assets/${a.md5}?width=180&height=180&rmode=pad&bgcolor=ffffff&clean=1`

    return <Box p={1}>
        <Paper style={{padding: '5px'}}>
            <Box display="grid" gridGap="15px" gridTemplateColumns={'180px 1fr'}>
                <div style={{maxWidth: '180px'}}>
                  <img alt="" style={{width: "180px", minHeight:"180px"}} src={uri} />
                    <Grid container justify = "center">
                        {a.published === "True" ? <Chip
                            icon={<CloudDone />}
                            label="Published"
                            color="secondary"
                            disabled={e.status === 'loading'}
                            onClick={() => publish(a)}
                        /> : <Button
                            variant="contained"
                            color="primary"
                            disabled={e.status === 'loading'}
                            onClick={() => publish(a)}
                            startIcon={<CloudUpload />}>Publish</Button>
                        }
                    </Grid>

                </div>
                <div style={{maxWidth: 'calc(100% - 15px - 180px -15px)'}}>
                    {Object.keys(a)
                        //.filter(i => !i.startsWith('asset'))
                        .map(k => renderMetaEntry(k, a))}
                </div>
                {/*<pre>{JSON.stringify(a, null, 2)}</pre>*/}
            </Box>
        </Paper>
    </Box>
}
const nonMetaProperties = ['editionId','isbn','assetTypeDisplayName']
function renderMetaEntry(key:string, source:any) {
    if (!source[key] || nonMetaProperties.includes(key))
        return
    const humanKey = key.replace(/_/g, ' ')
        .trim()
        .replace(/\b[A-Z][a-z]+\b/g, function(word) {
            return word.toLowerCase()
        })
        .replace(/^[a-z]/g, function(first) {
            return first.toUpperCase()
        })

    return <div><strong style={{display: 'inline-block', minWidth: '180px'}}>{humanKey}:</strong> <span>{source[key]}</span></div>
}

function groupBy(property: string, array: any[]){
    const ret = {} as {[key: string]: any}
    for (const i of array){
        if (!(i[property] in ret)){
            ret[i[property]] = [] as any[]
        }
        ret[i[property]].push(i)
    }
    return Object.values(ret);
}