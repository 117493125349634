import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {getDocument, getEmpty, postDocument} from './editorAPI'

export interface EditorState {
  readonly: boolean
  uri: string
  json: any
  jsonSchema: any
  jsonUiSchema: any
  keyPath: string | null,
  status: 'init' | 'loading' | 'ready' | 'saving' | 'error'
  errorMessage: string,
  dirtyState: any
}

const initialState: EditorState = {
  readonly: true,
  uri: '',
  keyPath: null,
  json: null,
  jsonSchema: null,
  jsonUiSchema: null,
  status: 'init',
  errorMessage: '',
  dirtyState: null
}

export const refreshAsync = createAsyncThunk('editor/refresh', getDocument);
export const getEmptyAsync = createAsyncThunk('editor/getEmptyAsync', getEmpty);
export const saveAsync = createAsyncThunk('editor/save', postDocument);

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setJson: (state, action: PayloadAction<string>) => {
      state.json = action.payload
    },
    setDirtyState: (state, action:PayloadAction<any>) => {
      state.dirtyState = action.payload.formData
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(refreshAsync.pending, (state) => {
        state.readonly = true;
        state.status = 'loading';
      })
      .addCase(refreshAsync.rejected, (state, action) => {
        state.readonly = true;
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(refreshAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.dirtyState = null;
        state.readonly = action.payload.readonly;
        state.uri = action.payload.uri;
        if (action.payload.json)
          state.json = JSON.parse(action.payload.json);

        if (action.payload.jsonSchema)
          state.jsonSchema = JSON.parse(action.payload.jsonSchema);

        if (action.payload.jsonUiSchema)
          state.jsonUiSchema = JSON.parse(action.payload.jsonUiSchema);
      })
      .addCase(saveAsync.pending, (state) => {
        state.readonly = true;
        state.status = 'saving';
      })
      .addCase(saveAsync.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.readonly = false;
          state.dirtyState = null;
          state.status = 'ready';
          state.json = JSON.parse(action.payload.serverRendition)
          const uri = `/json-editor/${action.payload.documentClass}/${action.payload.documentId}`
          if (window.location.href !== uri)
            window.location.replace(uri)
        } else {
          state.status = 'error';
          state.readonly = false;
          state.errorMessage = 'Validation Errors: \n' + action.payload.errors.map(e => {
            if (e.hasLineInfo) {
              return `  error: ${e.kind} @ ${e.path} line: ${e.lineNumber} position:${e.linePosition}`
            }else {
              return `  error: ${e.kind} @ ${e.path} `
            }
          }).join('\n')
        }
      })
      .addCase(saveAsync.rejected, (state, action) => {
        state.readonly = true;
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(getEmptyAsync.pending, (s) => {
        s.status = 'loading'
        s.readonly = true
      })
      .addCase(getEmptyAsync.fulfilled, (state, action) => {
        console.log('getEmptyAsync.fulfilled', action)
        state.status = 'ready';
        state.readonly = action.payload.readonly;
        state.uri = action.payload.uri;
        if (action.payload.json)
          state.json = JSON.parse(action.payload.json);
        else
          state.json = action.payload.json;

        if (action.payload.jsonSchema)
          state.jsonSchema = JSON.parse(action.payload.jsonSchema);
        else
          state.jsonSchema = action.payload.jsonSchema

        if (action.payload.jsonUiSchema)
          state.jsonUiSchema = JSON.parse(action.payload.jsonUiSchema);
        else
          state.jsonUiSchema = action.payload.jsonUiSchema;

        state.keyPath = action.payload.keyPath ?? null;
      })
      .addCase(getEmptyAsync.rejected, (state, action, ) =>{
        state.status = 'error'
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
    }
});

export const {setJson} = editorSlice.actions
export const selectEditor = (state: RootState) => state.editor;
export default editorSlice.reducer;
