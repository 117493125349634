import {get, getRaw} from '../../lib/apiClient'
import {Liquid} from "liquidjs";

export interface TemplateAndData {
    template: string
    data: any
}
const engine = new Liquid();

engine.registerFilter("parse-json", (value) => JSON.parse(value))
engine.registerFilter("format-json", (value) => JSON.stringify(value, null, 2))

export async function getRenderedTemplate({templateUri, dataUri}: {templateUri?: string, dataUri?: string}):
    Promise<string|null> {
    if (!templateUri || !dataUri)
        return null
    const template$ = get<[{liquid: string}]>(`query/TemplateByUrl?url=${templateUri}`)
    const data$ = getRaw<any>(`${dataUri}`)
    const [template, data] = await Promise.all([template$, data$])
    return await engine.parseAndRender(template[0].liquid, data);

}
