import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {EditionSummary, getEditionSummary} from "./editionPickerApi";

export interface EditionPickerState {
  status: 'init' | 'loading' | 'ready' | 'saving' | 'error'
  isbn: string,
  editions: EditionSummary[],
  errorMessage: string
}

const initialState: EditionPickerState = {
  status: 'init',
  isbn: '',
  editions: [],
  errorMessage: '',
}

export const refreshSummaryAsync = createAsyncThunk('editionSlice/refreshSummary', getEditionSummary);


export const editionSlice = createSlice({
  name: 'editionPicker',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    editionSelected: (state, action: PayloadAction<EditionSummary>) => {
      console.log('EDITION SELECTED', action)
      state.isbn = action.payload.isbn
    },
    editionCleared: (state) => {
      state.isbn = ''
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(refreshSummaryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshSummaryAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(refreshSummaryAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.editions = action.payload
      })
  },
});

//export const { editionSelected, editionCleared } = editionSlice.actions;
export const selectEditionPicker = (state: RootState) => state.editionPicker;
export default editionSlice.reducer;
