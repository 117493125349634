import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {EditionAsset, getAllEditionAssets, getEditionSummary, publishAsset} from "./editionAssetsApi";

export interface EditionAssetState {
  status: 'init' | 'loading' | 'ready' | 'saving' | 'error'
  isbn: string | null,
  title: string,
  imageUri: string | null
  errorMessage: string
  assets: EditionAsset[]
}

const initialState: EditionAssetState = {
  status: 'init',
  isbn: null,
  title: '',
  imageUri: null,
  assets: [],
  errorMessage: ''
}

export const getAssetsAsync = createAsyncThunk('editionAssetState/getAssetsAsync', getAllEditionAssets);
export const getEditionSummaryAsync = createAsyncThunk('editionAssetState/getEditionSummary', getEditionSummary);
export const publishAssetAsync = createAsyncThunk('editionImagePickerSlice/publishAssetAsync', publishAsset);

export const editionAssetSlice = createSlice({
  name: 'editionAssetSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetsAsync.pending, (state) => {
        state.status = 'loading';
        state.errorMessage = '';
      })
      .addCase(getAssetsAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(getAssetsAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.assets = action.payload
      })
      .addCase(publishAssetAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(publishAssetAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(publishAssetAsync.fulfilled, (state, action) => {
        state.status = 'ready';

      })
      .addCase(getEditionSummaryAsync.fulfilled, (state, action) => {
        state.title = action.payload?.title ?? ''
        state.imageUri = action.payload?.md5 ? `/image-assets/${action.payload?.md5}?width=180&height=180&rmode=pad&bgcolor=ffffff&clean=1` : null
      })
  },
});

export const selectEditionAssets = (state: RootState) => state.editionAssets;
export default editionAssetSlice.reducer;
