import {EditionPicker} from "../../editionPicker/editionPicker";
import {EditionSummary} from "../../editionPicker/editionPickerApi";
import {useEffect, useState} from "react";

let idx= 0
export function EditionPickerWidget(props: any) {
  const {value, onChange, label} = props;
  const [isbn, setIsbn] = useState(value)
  useEffect(()=> {
    //console.log(`EditionPickerWidget[${label}] props.value`, [value], idx++)
    setIsbn(value)
  }, [value])

  const change = (e:EditionSummary) => {
    console.log(`EditionPickerWidget[${label}] change`, e, value, idx++);
    const _isbn = e ? e.isbn : null
    if (_isbn !== isbn) {
      onChange(_isbn)
      // setIsbn(_isbn)
    }
  }
  return <>
    <EditionPicker label={label} isbn={isbn} editionSelected={change} />
  </>

}



