import {get, post} from '../../lib/apiClient'

export interface EditionAsset {
  edition_Id: string
  isbn: string
  title: string
  md5: string
  language: string
  published: string
  assetPath:string
}

export async function getAllEditionAssets(isbn?: string): Promise<EditionAsset[]> {
  if (!isbn)
    return []
  return get<EditionAsset[]>(`query/EditionImageAssets?isbn=${isbn}`)
}

// export async function getEditionAssets(isbn?: string): Promise<EditionAsset[]> {
//   if (!isbn)
//     return []
//   return get<EditionAsset[]>(`query/EditionPublishedAssetsByIsbn?isbn=${isbn}`)
// }

export async function publishAsset(asset: EditionAsset): Promise<void> {
  if (!asset)
    return

  const hash = asset.md5
  const path = `${asset.isbn}/a/${asset.assetPath}`
  await post<void>(`assets/publish/${hash}/${path}`, '')
}


export interface EditionSummary {
  edition_Id: string
  isbn: string
  title: string
  md5: string
  language: string
}

export async function getEditionSummary(isbn?: string): Promise<EditionSummary|null> {
  if (!isbn)
    return null
  const summary = await get<EditionSummary[]>(`query/EditionSummaryByIsbn?isbn=${isbn}`)
  if (summary && summary.length === 1)
    return summary[0]
  return null
}
