import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {refreshAsync, selectEditor} from "./editorSlice";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ToolBar} from "../appBar/views";
import {Button, IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import JSONPretty from "react-json-pretty";
import {Alert} from "@material-ui/lab";

export function JsonView() {
  const e = useAppSelector(selectEditor)
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(e.status)
  const [errorMessage, setErrorMessage] = useState(e.errorMessage)
  const params = useParams();
  const {documentClass, documentId} = params;
  const navigate = useNavigate();
  useEffect(() => {
    if (documentClass && documentId) {
      dispatch(refreshAsync({documentClass, documentId}));
    }
  }, [dispatch, documentClass, documentId])

  useEffect(() => {
    setStatus(e.status)
    setErrorMessage(e.errorMessage)
  }, [e.status, e.errorMessage])

  const themes = {
    main: 'line-height:1.3;color:#666666;background:#fff;overflow:auto;white-space:pre-wrap;max-width:100vw',
    error: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;',
    key: 'color:#333;',
    string: 'color:#999;',
    value: 'color:#a6e22e;filter: brightness(80%)',
    boolean: 'color:#ac81fe;filter: brightness(80%)',
  }
  return <>
    <ToolBar/>
    <IconButton
      onClick={() => navigate('/json-editor/' + documentClass)}
    >
      <ArrowBack/>
    </IconButton>
    <Button onClick={() => navigate(`/json-editor/${documentClass}/${documentId}`)}>Edit</Button>
    <JSONPretty id="json-pretty" data={e.json} theme={themes}></JSONPretty>
    <Alert>{status}: {errorMessage}</Alert>
  </>
}