//const api = "https://test.zephan.dev"

const defaults = {
  api: localStorage.getItem('api') || "https://data.phaidon.com",
  apiKey: localStorage.getItem('apikey') || ""
}

const api = defaults.api
let apiKey = defaults.apiKey

export interface ApiResponse<T> {
  success: boolean
  error?: {
    code: number,
    message: number
  }
  data: T
}

async function getBearerToken(){
  if (apiKey) return apiKey;
  const date = new Date()
  date.setDate(date.getDate() + 1);
  const request = await fetch(`${api}/account/issue/${date.toISOString()}/all`,
    {
      credentials: "same-origin",
      method: 'POST',
      redirect: 'error'
    });
  const ret = await request.json() as ApiResponse<{token: string}>
  if(ret.success){
    apiKey = ret.data.token
    return apiKey
  }
  throw new Error('Failed to get authorization token!')
}
export async function getRoles(): Promise<string[]>{
  try {
    let request = await fetch(`${api}/account/get-roles`,
        {
          headers: {"X-Api-Key": await getBearerToken()},
          method: 'GET'
        });
    const ret = await request.json() as ApiResponse<string[]>
    if (ret.success) {
      return ret.data
    }

  } catch (e) {
    console.log(`ERROR - ${e}`)
  }
  const url = new URL(window.location.href)
  if (!url.searchParams.has('reattempt')) {
    url.searchParams.set('reattempt', 'true')
    window.location.href = url.toString()
  }

  throw new Error('Failed to get use roles!')
}



export async function get<T>(relUri: string): Promise<T> {
  const request = await fetch(`${api}/${relUri}`, {
    headers: {
      "X-Api-Key": await getBearerToken()
    }
  })

  const ret = await request.json() as ApiResponse<T>;
  if (!ret.success)
    throw new Error(`ApiError: ${ret.error?.code}: ${ret.error?.message}`)


  return ret.data
}

export async function getRaw<T>(relUri: string): Promise<ApiResponse<T>> {
  const request = await fetch(`${api}/${relUri}`, {
    headers: {
      "X-Api-Key": await getBearerToken()
    }
  })

  return await request.json() as ApiResponse<T>;
}


export async function post<T>(relUri: string, body: string): Promise<T> {
  const request = await fetch(`${api}/${relUri}`, {
    method: 'POST',
    body: body,
    headers: {
      "X-Api-Key": await getBearerToken()
    }
  })

  const ret = await request.json() as ApiResponse<T>;
  if (!ret.success)
    throw new Error(`ApiError: ${ret.error?.code}: ${ret.error?.message}`)


  return ret.data
}
