import DOMPurify from 'dompurify'
import {marked, Renderer} from 'marked'
const baseUri = localStorage?.api || 'https://data.phaidon.com'

marked.setOptions({
  baseUrl: 'https://www.phaidon.com/',
  renderer: new Renderer(),
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false
});

const rootImageRegex = /src=['"](.*?)['"]/g

function rep(match:any, p1:string, offset:number, source:string){
  if (p1.startsWith('http:') || p1.startsWith('https:'))
    return match
  return `src="https://www.phaidon.com/${p1}"`
}


export const demonstrationTemplate = `
<div>
    <div>DEMONSTRATION WRAPPER - HEADER</div>
    $content
    <div>DEMONSTRATION WRAPPER - FOOTER</div>
</div>
`

export function previewRendererFactory(template: string){
  if (template && template.indexOf('$content$') === -1)
    throw new Error('previewRendererFactory: template must be a string and contain $content where markdown will be rendered')

  return (plainText: string) =>
  {
    const replacedImageAssets = plainText
      //.replace(/(\/image-assets\/[0-9A-Fa-f]{32}\?)/g, baseUri + '$1width=300&')
      .replace(/\/image-assets\/[0-9A-Fa-f]{32}\?path=([^)]+)\)/g, `https://www.phaidon.com$1)`)
    const html = DOMPurify
      .sanitize(marked.parse(replacedImageAssets, {baseUrl: 'https://www.phaidon.com/'}));
    return template.replace('$content$', html.replace(rootImageRegex, rep))
  }
}
