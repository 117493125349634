import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {getAssets} from "./editionImagePickerApi";

export interface EditionImagePickerState {
  status: 'init' | 'loading' | 'ready' | 'saving' | 'error'
  isbn: string | null,
  errorMessage: string
  assets: any[]
}

const initialState: EditionImagePickerState = {
  status: 'init',
  isbn: null,
  assets: [],
  errorMessage: ''
}

export const getAssetsAsync = createAsyncThunk('editionImagePickerSlice/getAssetsAsync', getAssets);

export const editionImagePickerSlice = createSlice({
  name: 'editionImagePicker',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAssetsAsync.rejected, (state, action) => {
        state.status = 'error';
        state.errorMessage = action.error.message ?? 'ERROR!'
      })
      .addCase(getAssetsAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.assets = action.payload
      })
  },
});


export const selectEditionPicker = (state: RootState) => state.editionImagePicker;
export default editionImagePickerSlice.reducer;
