import {createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noPadding:{
            padding:0,
            margin:0
        },
        text: {
            margin: 0,
            width: '100%'
        },
    }),
);
export function HiddenWhenBlankWidget(props: any) {
    const {value, rawErrors, id, label} = props;
    const classes = useStyles();

    return <>
        {value ? <TextField
            className={classes.text}
            id={id}
            error={!!rawErrors}
            disabled
            label={label}
            helperText={rawErrors}
            value={value}
        /> : ''}
    </>
}
