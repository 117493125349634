import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getList } from './documentClassApi';

export interface DocumentListState {
  status: 'init' | 'loading' | 'ready'
  classes: string[]
}

const initialState: DocumentListState = {
  status: 'init',
  classes: []
}

export const refreshAsync = createAsyncThunk(
  'documentClassList/refresh',
  async () => {
    return await getList();
  }
);

export const documentClassListSlice = createSlice({
  name: 'documentClassList',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(refreshAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshAsync.fulfilled, (state, action) => {
        state.status = 'ready';
        state.classes = action.payload
      });
  },
});

export const selectDocumentListSlice = (state: RootState) => state.documentClassList;
export default documentClassListSlice.reducer;
