import {Button, Card, CardActions, CardContent, Container, Typography} from "@material-ui/core";
import classes from "./Home.module.scss";
import {ToolBar} from "../appBar/views";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getRoles} from "../../lib/apiClient";
import {Alert} from "@material-ui/lab";

interface NavConfig{
  name: string
  link: string
  description: string
  action:string
  requiredRoles: string[]
}
const links = [
  {
    name: 'Article Images',
    link: '/edition-assets/',
    description: 'View and publish images to the public website.',
    action:'Manage',
    requiredRoles: ['apiPublish', 'apiBuild']
  },
  {
    name: 'Publishing',
    link: '/publish-site/',
    description: 'Publish website content using latest data',
    action:'Publish Site',
    requiredRoles: ['apiPublish', 'apiBuild']
  },
  {
    name: 'Article Editor',
    link: '/json-editor/Article/',
    description: 'View, edit and create new articles',
    action:'Editor',
    requiredRoles: ['apiArticleR', 'apiArticleF']
  },
  {
    name: 'Books Product Editor',
    link: '/json-editor/BooksProduct/',
    description: 'Edit additional properties for phaidon.com that do not appear in books',
    action:'Editor',
    requiredRoles: ['apiPublish']
  },
  {
    name: 'Non-Books Product Editor',
    link: '/json-editor/Product/',
    description: 'Edit properties for phaidon.com products that do not live in books',
    action:'Editor',
    requiredRoles: ['apiPublish']
  },
  {
    name: 'Data Document Editor',
    link: '/json-editor/',
    description: 'For expert users',
    action:'Editor',
    requiredRoles: []
  },
]

function matchRole(roles: string[], requiredRoles: string[]){
  if(requiredRoles.length === 0)
    return true

  for(const r of roles){
    if (requiredRoles.includes(r))
      return true
  }
  return false
}

export function HomeView(){
  const navigate = useNavigate();
  const [roles, setRoles] = useState([] as string[])
  const [nav, setNav] = useState([] as NavConfig[])

  useEffect( () => {
    (async ()=>{
      const roles = await getRoles();
      console.log('roles',roles)
      setRoles(roles)
    })()
  }, []);

  useEffect(()=>{
    setNav(links.filter(i => matchRole(roles, i.requiredRoles)))
  }, [roles])

  if (nav.length === 0) {
    return <>
      <ToolBar />
      <Container className={classes.centerMiddle}>
        <Alert severity={"warning"} >
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            You don't appear to have access to any options.
            <br/> Please contact support for more information.
          </Typography>
        </Alert>
      </Container>
    </>
  }

  return <>
      <ToolBar />
      <Container className={classes.root}>
        {
          nav.map(i => <Card key={i.name} className={classes.card}>
            <CardContent className={classes.fillHeight}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {i.name}
              </Typography>
              <Typography variant="body2" component="p">
                {i.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate(i.link)}>{i.action}</Button>
            </CardActions>
          </Card>)
        }
      </Container>
    </>
}