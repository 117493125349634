import {Button, Container, createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import {useEffect} from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding:{
      padding:0,
      margin:0
    },
    text: {
      margin: 0,
      width: '100%'
    },
  }),
);

export function GuidWidget(props: any) {
  const {value: guid, onChange: setGuid, rawErrors, id, label} = props;
  const classes = useStyles();
  function renderGenerate() {
    if (!guid)
      return <Button variant="contained" color="primary" onClick={() => setGuid(uuidv4().toUpperCase())}>Generate Guid</Button>
    else
      return <></> //<Button onClick={() => setGuid(uuidv4())}>Regenerate</Button>
  }

  useEffect(() => {
    if(!guid) {
      setGuid(uuidv4().toUpperCase())
    }
  }, [guid, setGuid])

  return <Container className={classes.noPadding}>
    {guid && <TextField
        className={classes.text}
        id={id}
        error={!!rawErrors}
        disabled
        label={label}
        helperText={rawErrors}
        value={guid}
      /> }
    {!guid && renderGenerate()}
  </Container>


}


