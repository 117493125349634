import {useNavigate} from "react-router-dom";
import {EditionPicker} from "./editionPicker";
import {Box} from "@material-ui/core";
import {ToolBar} from "../appBar/views";
import {EditionSummary} from "./editionPickerApi";

export function EditionSearchView(props: any){
    const navigate = useNavigate();

    return <>
        <ToolBar />
        <Box p={1}>
            <EditionPicker label="Select Edition"  editionSelected={(i:EditionSummary) => navigate(`/edition-assets/${i.isbn}`)} />
        </Box>
    </>


}