import ReactFlow, {Handle, Position, ReactFlowProvider, useNodes} from 'react-flow-renderer';
import {useMemo, useState} from 'react';
import classes from "./ServerBackend.module.scss";

import create from "zustand";
import {Node} from "react-flow-renderer/dist/esm/types/nodes";
import {Edge} from "react-flow-renderer/dist/esm/types";
import {Skeleton} from "@material-ui/lab";
import dayjs from "dayjs";

const nodeOptions = {
  draggable: false,
  selectable: false
}
const edgeOptions = {
  animated: true,
  style: {
    stroke: 'black',
  },
};

export interface BackendNodeData {
  label:string
  hash:string|null
  date?:string|null
}
export interface WebserverNodeData {
  label:string
  url:string
  linkWords: string
}
export interface DiagStore {
  nodes: (Node<BackendNodeData> | Node<WebserverNodeData>)[],
  edges: Edge[],
  connectedTo: (source: string, targets: string[]) => void,
  setHashData: (hashData: {PinkQuick: string, ArticlesPink: string, QuickOrange: string, ArticlesOrange: string}) => void,
}

let id=1000;
function nextId(){
  return `auto-${++id}`
}
export const diagramStore = create<DiagStore>((set, get) => ({
  nodes: [
    {...{ type: 'server',  id: 'production', data: {label:'Production', url: 'https://www.phaidon.com', linkWords:'www.phaidon.com', connection: Position.Left}, position: {x:320, y:40}}, ...nodeOptions},
    {...{ type: 'server',  id: 'staging',    data: {label:'Staging', url: 'https://staging.phaidon.com', linkWords:'staging.phaidon.com', connection: Position.Right}, position: {x:320, y:120}}, ...nodeOptions},
    {...{ type: 'backend', id: 'OrangeQuick',     data: {hash: null, label:'Orange', connection: Position.Right}, position: {x:0, y:0}}, ...nodeOptions},
    {...{ type: 'backend', id: 'PinkQuick',       data: {hash: null, label:'Pink', connection: Position.Left}, position:{x:660, y:0}}, ...nodeOptions},
    {...{ type: 'backend', id: 'OrangeArticles',     data: {hash: null, label:'Orange Articles', connection: Position.Right}, position: {x:0, y:120}}, ...nodeOptions},
    {...{ type: 'backend', id: 'PinkArticles',       data: {hash: null, label:'Pink Articles', connection: Position.Left}, position:{x:660, y:120}}, ...nodeOptions},
  ],
  edges: [
     // { id:nextId(), target: 'OrangeQuick', sourceHandle:'production-orange',  source: 'production' },
     // { id:nextId(), target: 'PinkQuick', sourceHandle:'staging-pink',  source: 'staging'},
  ],
  connectedTo: (source:string, targets: string[]) => {
    const edges = [
      ...get().edges.filter(i => i.source !== source),
      ...targets.map(target => ({id:nextId(), source,  target, sourceHandle: `${source}-${target}`}))
      ]
    set({edges})
 },
  setHashData: (hashData:{PinkQuick: string, ArticlesPink: string, QuickOrange: string, ArticlesOrange: string}) => {
    const nodes = get().nodes as Node<BackendNodeData>[]
    for(const n of nodes.filter(i => i.type === 'backend') as Node<BackendNodeData>[]){
      const d = (hashData as any)[n.id]
      const [hash,data] =(d||"\t").split("\t") as string[]
      n.data.hash = hash
      n.data.date = dayjs(data).format('YYYY-MM-DD HH:mm')
    }
    set({nodes})
  }

}))


export default function ServerBackend() {
  const nodeTypes = useMemo(() => ({ backend: Backend, server:Webserver }), []);
  const { nodes, edges } = diagramStore();
  return (
    <ReactFlowProvider>
      <ReactFlow fitView
                 defaultEdgeOptions={edgeOptions}
        defaultNodes={nodes}
        defaultEdges={edges}
        nodeTypes={nodeTypes}
      />
    </ReactFlowProvider>
  );
}


function Backend(n: any) {
  const nodes = useNodes()
  const [s, setState ] = useState(n)

  if (s.data.hash !== n.data.hash) {
    setState(n)
  }
  return <>
      <div className={classes.backend} id={`inner-${s.id}`}>
        {s.data.label}
        <br/><span className={classes.hash}>{s.data.hash !== null ? s.data.hash : <Skeleton variant="text" />}</span>
        <br/>{s.data.date !== null ? s.data.date : <Skeleton variant="text" />}
      </div>
      <Handle type="target" position={s.data.connection} id="a" />
    </>
}

function Webserver(n: any) {
  const [s, setState] = useState(n)
  if (s.data !== n.data) {
    setState(s)
  }
  return <>
    <div className={classes.webserver}>
      {s.data.label}
      <br/><a href={s.data.url}>{s.data.linkWords}</a>
    </div>
    <Handle type="source" position={Position.Right} id={s.data?.label?.toLowerCase() + "-PinkQuick"}/>
    <Handle type="source" position={Position.Right} id={s.data?.label?.toLowerCase() + "-PinkArticles"}/>
    <Handle type="source" position={Position.Left} id={s.data?.label?.toLowerCase() + "-OrangeQuick"}/>
    <Handle type="source" position={Position.Left} id={s.data?.label?.toLowerCase() + "-OrangeArticles"}/>

  </>
}